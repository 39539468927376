import React, { useState } from "react";
import coach1 from "../Assets/coach1.jpg";
import coach2 from "../Assets/coach2.jpg";
import coach3 from "../Assets/coach3.jpg";
import coach4 from "../Assets/coach4.png";
import PopupDialog from "../Components/popup";
import "./About.css";
import Fall from "./FallComponent";
import UpComingEvents from "./UpComingEvents";
import UTSA from "./UTSA_Events";
import Weekly from "./Weekly";

function CoachCard({ card, index }) {
  const [showMore, setShowMore] = useState(false);

  return (
    <div key={index} className="mb-4">
      <div className="card w-100 h-100">
        <img src={card.image} className="card-img-top" alt={`Player ${index + 1}`} />
        <div className="card-body">
          <h5 className="card-title">{card.name}</h5>
          <p className={`card-text ${showMore ? 'show-more' : 'truncate'}`}>{card.content}</p>
          <button onClick={() => setShowMore(!showMore)} className="btn btn-outline-primary primary-text">
            {showMore ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>
    </div>
  );
  return (
    <div key={index} className="mb-4">
      <div className="card w-100 h-100">
        <img src={card.image} className="card-img-top" alt={`Player ${index + 1}`} />
        <div className="card-body">
          <h5 className="card-title">{card.name}</h5>
          <p className={`card-text ${showMore ? 'show-more' : 'truncate'}`}>{card.content}</p>
          <button onClick={() => setShowMore(!showMore)} className="btn btn-outline-primary primary-text">
            {showMore ? 'Show Less' : 'Show More'}
          </button>
        </div>
      </div>
    </div>
  );
}

const About = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [liked, setLiked] = useState(false); // State to handle like button

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleJoinNowClick = () => {
    window.open("https://docs.google.com/forms/d/1OTYlNKc7jSmpFC9wWb_hwyIYKMB6cHcdqfJbVsRDXVs/viewform?edit_requested=true", "_blank");
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleLikeButtonClick = () => {
    setLiked(!liked); // Toggle like state
  };

  const cardData = [
    {
      name: "Anil Peketi",
      image: coach4,
      content: `As a passionate tennis enthusiast, I have been deeply involved in the sport for the past 20 years. My journey began with playing in the vibrant local tennis community in South Jersey, where I developed my love for the game.
      Recognizing the importance of nurturing young talent, I initiated and trialed a comprehensive tennis camp last year. This camp is designed to support local junior players, helping them progress from foundational skills to competitive levels.
      I have actively worked with local communities to renovate and improve tennis facilities in neighboring towns. One of my proudest achievements is spearheading the renovation of the Larchmont Tennis Courts. After 14 months of collaboration with the local township, USTA and the Larchmont tennis community, the township approved $1.5 million in funding to renovate and build six brand new courts. It is truly cherishing to see so many young players and adults using the renovated courts, which opened earlier in spring 2024.`
    },
    {
      name: "Gary Cimperman",
      image: coach1,
      content: `Announcing Gary Cimperman as our new Camp Director. Gary is a highly accomplished tennis professional earning accolades from a number of organizations over his 13-year career. They include, but are not limited to, 2022 USTA Middle States Tournament Director of the Year, 2023 Best of Main Line - Best Sports Camp, 2023 Family Focus Media - Best Sports Camp, and 2024 Family Focus Media - Youth Sports Program, Family Favorite Honoree. Gary is well-respected in the tennis industry being asked for speaking engagements at conferences, conducting cardio clinics at national events, and even worked as a touring pro with PTR in Jamaica.`,
    },
    {
      name: "Brett Schuster",
      image: coach3,
      content: `Brett plays Division III tennis at The College of New Jersey, where was part of the 50-win club. Previously, he played first singles for Cherry Hill High School East. This summer will be his third year coaching tennis. Tennis has been a huge part of his life since he was a kid and he hopes to provide others with the same enjoyment as he's had.`,
    },
    {
      name: "Zach Van Meter",
      image: coach2,
      content: `I started dabbling in tennis in middle school, but really committed to it and stopped playing soccer at the end of eighth grade. With a lot of hard work I was able to bring my level up from bottom JV sophomore year to 2nd Doubles varsity in my junior year and 1st Doubles varsity my senior year. In my senior year, our Cherry Hill East team reached the state finals, and my individual doubles team reached the state semifinal. Since then, I have done some private and group coaching.`,
    }
  ];

  return (
    <div className="about-section-container">
      <h1 className="primary-subheading lh-1">Mission</h1>
      <p className="fs-5 text-break w-100">
        At NextGen Tennis, our mission is to inspire and nurture a lifelong passion for tennis within our community. We are committed to delivering exceptional coaching that cater to all skill levels, from beginners to competitive players. Through our comprehensive summer camps, dynamic clinics, competitive events, and diverse development activities, we aim to foster growth, sportsmanship, and excellence both on and off the court. By cultivating a supportive and engaging environment, we strive to develop not only skilled athletes but also confident, respectful, and well-rounded individuals who embody the true spirit of tennis.
      </p>

      <Fall />
      {/* <div className="video-container">
        <p className="primary-subheading lh-1 mt-3">Video Spotlight</p>
        <video width="100%" height="500" controls>
          <source src="/copy_CAC77028-1D7B-4E50-BE50-3F6ACC71BF1D (1).mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video> 
      </div> */}

      {/* <div className="video-container flex" id="vid">
        <video width="100%" height="500" controls=""><source src="/copy_CAC77028-1D7B-4E50-BE50-3F6ACC71BF1D (1).mp4" type="video/mp4">Your browser does not support the video tag.</video>
        <video width="100%" height="500" controls=""><source src="/NextGen - (Day at camp).mp4" type="video/mp4">Your browser does not support the video tag.</video>
        <video width="100%" height="500" controls=""><source src="NextGen Camp.mp4" type="video/mp4">Your browser does not support the video tag.</video>
      </div> */}
      {/* <div className="video-container flex" id="vid">
        <video width="100%" height="500" controls>
          <source src="/copy_CAC77028-1D7B-4E50-BE50-3F6ACC71BF1D (1).mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <video width="100%" height="500" controls>
          <source src="/NextGen - (Day at camp).mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <video width="100%" height="500" controls>
          <source src="/NextGen Camp.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div> */} 
  <p class="primary-subheading lh-1 mt-3">Video Spotlight</p>
  
<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4" id="vid">
      <div className="video-container">
        <video width="100%" height="500" controls>
          <source src="/copy_CAC77028-1D7B-4E50-BE50-3F6ACC71BF1D (1).mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="video-container">
        <video width="100%" height="500" controls>
          <source src="/NextGen - (Day at camp).mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="video-container">
        <video width="100%" height="500" controls>
          <source src="NextGen Camp.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>



      <UpComingEvents />
      <p className="primary-subheading lh-1 mt-3 ">Junior Summer Camp</p>
      <h1 className="primary-heading">
        June 24<sup>th</sup>  to Aug 21<sup>st</sup>  2025
      </h1>
      <Weekly />
      <UTSA />
      <img src="/NEW Junior png.png" width={"350px"} height={"250px"} className="primary-subheading lh-1 " />
      <p className="fs-5 text-break w-100 text-center">
        A more competitive team format that provides kids and teens the opportunity to play singles, doubles, and mixed doubles matches.
      </p>

      <div className="container mt-2 mb-5">
        <div className="row bg-success-subtle rounded-4 p-3 w-100">
          {[
            { week: 'Boys Only High School Players' },
            { week: '14 & Under Coed Intermediate' },
            { week: '12 & Under Coed Green Dot Ball' },
          ].map((item, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-4 mb-2 mt-2 d-flex justify-content-center align-items-center">
              <div className="card h-100" style={{ width: '200px', height: '300px !important' }}>
                <div className="card-body">
                  <h5 className="card-title">{item.week}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <p className="primary-subheading lh-1 mt-3">Junior Summer Camp</p>
      <h1 className="primary-heading">
        JUNE 25<sup>th</sup> - AUG 22<sup>nd</sup> 2024
      </h1>
      <p className="fs-5 text-break w-100">
        NextGen Tennis Performance Summer camps are the benchmark for excellence in tennis training. Our refined and continually evolving methodology is designed to help players reach their full potential through a comprehensive approach that includes technical, tactical, physical, and mental training. Our camps cater to players aged 8-18, offering customizable programs for all skill levels, with sessions available year-round to fit your schedule.
      </p>

      <Weekly />

      <div className="displayfles">
        {/* Optional sections commented out in the original code */}
        {/* Optional sections commented out in the original code */}
      </div>

      <div id="coaches" className="cards-container overflow-auto w-100">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 m-auto">
          {cardData.map((card, index) => (
            <CoachCard card={card} key={index} />
          ))}
        </div>
      </div>

      <PopupDialog open={openPopup} handleClose={handleClosePopup} />
    </div>
  );
};

export default About;
