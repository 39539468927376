// /* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import Logo from "../Assets/Logo.svg";
// import { BsCart2 } from "react-icons/bs";
// import { HiOutlineBars3 } from "react-icons/hi2";
// import Box from "@mui/material/Box";
// import Drawer from "@mui/material/Drawer";
// import List from "@mui/material/List";
// import Divider from "@mui/material/Divider";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import HomeIcon from "@mui/icons-material/Home";
// import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
// import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
// import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
// import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
// import tennislogo from '../Assets/Logo.png';
// import './Navbar.css'
// const Navbar = () => {
//   const [openMenu, setOpenMenu] = useState(false);
//   const menuOptions = [
//     {
//       text: "Home",
//       icon: <HomeIcon />,
//       link: "#home"
//     },
//     {
//       text: "Services",
//       icon: <CommentRoundedIcon />,
//       link: "#about"
//     },
//     {
//       text: "Camps",
//       icon: <ListOutlinedIcon />,
//       link: "#events"
//     },
//     {
//       text: "Contact us",
//       icon: <PhoneRoundedIcon />,
//       link: "#contact"
//     },
//   ];
//   return (
//     // className="backgroundone"
//     <nav >
//       <div className="navlogocontainer">
//         <img  src={tennislogo} alt="Logo" />
//         <p>NEXTGEN TENNIS PERFORMANCE</p>
//       </div>
//       <div className="navbar-links-container">
//         <a href="#home">Home</a>
//         <a href="#about">Services</a>
//         <a href="#events">Camps</a>
//         <a href="#contact">Contact us</a>
//         <button className="buttongap primary-button">Free Pass</button>
//         <button className="primary-button">Join Now</button>
//       </div>
//       <div className="navbar-menu-container">
//         <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
//       </div>
//       <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
//         <Box
//           sx={{ width: 250 }}
//           role="presentation"
//           onClick={() => setOpenMenu(false)}
//           onKeyDown={() => setOpenMenu(false)}
//         >
//           <List>
//             {menuOptions.map((item) => (
//               <ListItem key={item.text} disablePadding>
//                 <ListItemButton component="a" href={item.link}>
//                   <ListItemIcon>{item.icon}</ListItemIcon>
//                   <ListItemText primary={item.text} />
//                 </ListItemButton>
//               </ListItem>
//             ))}
//           </List>
//           <Divider />
//         </Box>
//       </Drawer>
//     </nav>
//   );
// };

// export default Navbar;

import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import HomeIcon from "@mui/icons-material/Home";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { HiOutlineBars3 } from "react-icons/hi2";
import Logo from "../Assets/Logo.png";
import PopupDialog from "../Components/popup";
import "./Navbar.css";

const Navbar = () => {
  const [openPopup, setOpenPopup] = useState(false);

  const handleJoinNowClick = () => {
    window.open(
      "https://docs.google.com/forms/d/1OTYlNKc7jSmpFC9wWb_hwyIYKMB6cHcdqfJbVsRDXVs/viewform?edit_requested=true",
      "_blank"
    );
  };

  const handleUTRlinkClick = () => {
    window.open("https://app.utrsports.net/clubs/951388", "_blank");
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
      link: "#home",
    },
    {
      text: "Camps",
      icon: <ListOutlinedIcon />,
      link: "#events",
    },
    {
      text: "Coaches",
      icon: <CommentRoundedIcon />,
      link: "#coaches",
    },

    {
      text: "Contact us",
      icon: <PhoneRoundedIcon />,
      link: "#contact",
    },
  ];

  return (
    <nav className="w-100 p-3 rounded-2 flex justify-content-center align-items-center">
      <div className="footerlogo d-none d-sm-flex">
        <img src={Logo} alt="Logo" style={{ width: "80px" }} />
        <div>
          <h2 style={{ margin: "0 10px" }}>NextGen</h2>
          <h6 style={{ fontSize: "0.66rem", margin: "0 10px" }}>
            TENNIS <span style={{ margin: "0 4px" }}> </span> PERFORMANCE
          </h6>
        </div>
      </div>

      <div className="navbar-links-container w-75 gap-3 flex justify-content-end  align-items-center">
        <a href="#home">Home</a>
        <a href="#events">Camps</a>
        <a href="#coaches">Coaches</a>
        <a target="_blank" href="https://docs.google.com/forms/d/1OTYlNKc7jSmpFC9wWb_hwyIYKMB6cHcdqfJbVsRDXVs/viewform?edit_requested=true">Contact Us</a>
        {/* <a href="#contact">Contact Us</a> */}

        <button className="primary-button " onClick={handleJoinNowClick}>
          Free Trial / Drop In
        </button>
        <button className="primary-button" onClick={handleUTRlinkClick}>
          UTR
        </button>
        <button className="primary-button " onClick={handleJoinNowClick}>
          Join Now
        </button>
        <div>
          <h6  class="contact-text">
            Phone : (856)-581-1072 <span> Email : serve2win9@gmail.com</span>
          </h6>
          <div className="social-icons">
            <a href="https://www.facebook.com/nextgentennisperf" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
              </svg>
            </a>
            <a href="https://www.instagram.com/nextgen_performance23/" target="_blank" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
              </svg>
            </a>
          </div>
        </div>

      </div>
      <div className="navbar-menu-container d-flex justify-content-between d-sm-none justify-content-sm-end gap-0 gap-sm-2 w-100 align-items-center">
        <div className="d-flex align-items-center d-sm-none  ">
          <img src={Logo} alt="Logo" style={{ width: "50px" }} />
          <div className=" ">
            <h2 style={{ margin: "0 10px" }}>NextGen</h2>
            <h6 style={{ fontSize: "0.66rem", margin: "0 10px" }}>
              TENNIS <span style={{ margin: "0 2px" }}> </span> PERFORMANCE
            </h6>
          </div>
        </div>
        <button className="primary-button  " onClick={handleJoinNowClick}>
          Join Now
        </button>
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton component="a" href={item.link}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <div className="d-flex flex-column p-3 gap-3">
            <button className="primary-button " onClick={handleJoinNowClick}>
              Free Trial / Drop In
            </button>
            <button className="primary-button" onClick={handleUTRlinkClick}>
              UTR
            </button>
            <button className="primary-button " onClick={handleJoinNowClick}>
              Join Now
            </button>
          </div>
        </Box>
      </Drawer>
      <PopupDialog open={openPopup} handleClose={handleClosePopup} />
    </nav>
  );
};

export default Navbar;
